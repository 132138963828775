export function validate(...fields) {
  const form =
    fields[0].parentNode.name || fields[0].parentNode.parentNode.name;

  return fields.reduce((isValidated, field) => {
    if (!field.value.trim()) {
      field.classList.add(`${form}_value_required`);

      isValidated = false;
    }

    return isValidated;
  }, true);
}

export function resetStyles(elem) {
  const form = elem.parentNode.name || elem.parentNode.parentNode.name;

  elem.classList.remove(`${form}_value_required`);
}

export function submitSearchForm(e, box) {
  e.preventDefault();

  const data = new FormData(e.target);

  const query = data.get('search').trim();

  if (!query) return;

  location.href = `/search?query=${query}&box=${box}`;
}

export function validateDate(input) {
  if (!input.value || input.value.match(/^(\d|-)+$/))
    input.dataset.validatedValue = input.value;
  else input.value = input.dataset.validatedValue;
}

export function sliceLength(field, length) {
  if (field.value.length > length) field.value = field.value.slice(0, length);
}

export function setCustomValidity(elem, message) {
  if (!elem) return;

  elem.addEventListener('invalid', (e) => {
    e.target.setCustomValidity('');

    if (!e.target.validity.valid) e.target.setCustomValidity(message);
  });

  elem.addEventListener('input', (e) => e.target.setCustomValidity(''));
}

export function resetValidity(elem, violation) {
  if (!elem) return;

  elem.addEventListener('invalid', (e) => {
    if (e.target.validity[violation]) e.target.setCustomValidity('');
  });
}

// https://team.linkorb.com/cards/5974
// https://team.linkorb.com/cards/6090
// https://team.linkorb.com/cards/7855
// https://team.linkorb.com/cards/8015
export function prefillSurveyForm(formXuid, survey, user) {
  if (
    ![
      'puYs761oT-2RUtW62exsjQ',
      'KcX8pGPFQ-aRo5QecZ2maQ',
      'C9DatBYGQ0WC8_PQNGwn7w',
      'wZitKGHgTkO4A5BjO1SQCw'
    ].includes(formXuid) ||
    !user?.username
  )
    return;

  const [name, surname] = user.displayName.split(' ');

  survey.setValue('Voornaam', name);
  survey.setValue('email', user.email);
  survey.setValue('Achternaam', surname);
  survey.setValue('Name', user.displayName);
  survey.setValue('Tel-nummer', user.phone);
  survey.setValue('E-mailadres', user.email);
}

// https://team.linkorb.com/cards/5974
// https://team.linkorb.com/cards/6090
export function openEmailClientWithFormData(formXuid, formData) {
  const body = `Hi zwangerenportaal.nl medewerker,\n\nZou je mij verder kunnen helpen met onderstaande vraag?\n\n${Object.entries(
    formData
  ).reduce((string, item) => {
    return (string += `• ${item[0]}: ${item[1]}\n`);
  }, '')}\nGroet, ${formData.Voornaam}`;

  if (
    (formXuid === 'puYs761oT-2RUtW62exsjQ' &&
      formData.antwoord_geholpen === false) ||
    formXuid === 'KcX8pGPFQ-aRo5QecZ2maQ'
  )
    location.href = `mailto:info@zwangerenportaal.nl?subject=${
      formData.Vraag
    }&body=${encodeURIComponent(body)}`;
}

export function isEmail(val) {
  return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(val);
}

export function isPostCode(val) {
  return /^\d{4}-[a-zA-Z]{2}$/.test(val);
}
