export default {
  '/': {
    title: 'Zwangerenportaal - Zwanger, baby, verloskundigen en meer..',
    description:
      'zwangerenportaal.nl beantwoort al je vragen over zwanger worden, zwanger, geboorte, je baby en dossier. Bekijk de zwangerschapskalender en babykalender.',
    tags: 'Zwangerenportaal, zwanger worden, zwanger, geboorte, baby, weken zwanger, inloggen, zwangerschapstest, Vruchtbare dagen, zwangerschapskleding, verloskundige, borsten, baby shower,positiekleding, oxytocine, verloskundige, baarmoeder, zwangerschapskleding, echo’s, zwangerschapskwaaltjes, gezondheid zwangerschap'
  },
  '/channels-overview': {
    title: 'Kanalen overzicht',
    description:
      'Neem een kijkje op de verschillende kanalen van zwangerenportaal en lees meer over interessante zwangerschapsinformatie, blogs, heerlijke zwangerschapsrecepten en nog veel meer!',
    tags: 'Zwangerenportaal, zwangerenportaal kanalen, zwangerschapsblog, zwangerschapsvlog, zwangerschapsrecepten, zwangerschapsvideo’s, zwangerschapsinformatie, voorbereiding bevalling, eten zwangerschap, ontwikkeling baby, zwangerschapskalender, zwangerschapskwaaltjes, babykwaaltjes, complicaties bevalling, aanbiedingen zwangerschap, ziekenhuisbevalling, thuisbevalling'
  },
  '/contacts': {
    title: 'Contact',
    description:
      'Heb je vragen voor zwangerenportaal? Neem hier gerust contact met ons op en we helpen je graag verder met al je vragen of opmerkingen.',
    tags: 'Zwangerenportaal, zwanger, zwanger worden, zwangerschapstest, contact zwangerenportaal, inloggen zwangerenportaal, account zwangerenportaal, aanmelden zwangerenportaal, vruchtbaarheidsbehandelingen, zwangerschapssymptomen, voorbereiding bevalling, babyvoeding, babyverzorging, kinderopvang, sporten zwangerschap, eten zwangerschap, zwangerschapskwaaltjes, zwanger worden lukt niet, familie voordeel programma, ziekenhuisbevalling, thuisbevalling'
  },
  '/faq': {
    title: 'Faq',
    description:
      'Heb je een vraag voor zwangerenportaal? Bekijk dan hier eerst of jouw vraag tussen de veelgestelde vragen terug te vinden is.',
    tags: 'zwangerenportaal, veelgestelde vragen zwangerenportaal, FAQ zwangerenportaal, contact zwangerenportaal, account zwangerenportaal, privacy, vruchtbaarheidsbehandelingen, zwangerschapssymptomen, voorbereiding bevalling, babyvoeding, babyverzorging, kinderopvang, sporten zwangerschap, eten zwangerschap, zwangerschapskwaaltjes, zwanger worden lukt niet, familie voordeel programma, zwanger, zwanger worden, ziekenhuisbevalling, thuisbevalling'
  },
  '/search': {
    title: 'Zoeken',
    description:
      'Wil je  iets specifieks vinden? Zoek het dan in de zoekbalk van zwangerenportaal en vind het direct in de zoekresultaten.',
    tags: 'Zwangerenportaal, zoekresultaten zwangerenportaal, zoeken zwangerenportaal, vruchtbaarheidsbehandelingen, zwangerschapssymptomen, voorbereiding bevalling, babyvoeding, babyverzorging, kinderopvang, sporten zwangerschap, eten zwangerschap, zwangerschapskwaaltjes, zwanger worden lukt niet, familie voordeel programma, zwanger, zwanger worden, oxytocine,  ovulatietest, ben ik zwanger, waarom word ik niet zwanger, zwangerschapsdossier, medische vragen zwangerschap, ziekenhuisbevalling, thuisbevalling'
  },
  '/voordeel': {
    description:
      'Het Familie voordeel Programma. Fantastische aanbiedingen voor zwangeren. Grote kortingen op babyvoeding, luiers, babyvoeding, geboortekaartjes, kinderwagens en zwangerschapskleding.',
    tags: 'Zwangerenportaal, Familie voordeel Programma, Korting, aanbiedingen, babyvoeding, luiers, luier aanbieding, babyvoeding, kinderwagens en zwangerschapskleding, kortingscodes, geboortekaartjes, zwangerschapsbh, positiekleding, zwangerschapsbroek, zwangerschapsshoot, newbornshoot'
  },
  '/discount-program-register': {
    title: 'Payment - familie Voordeelprogramma',
    tags: 'Familie Voordeel Programma, familie korting, familie voordeel, voordeelpas, kortingscode, korting, babyuitzetlijst, babyspullen, zwangerschapskleding, positiekleding, babykamer, babyspullen kopen, zwangerschapsspullen, familie uitstapjes, gezinskorting, zwangerschapsbh, zwangerschapsshoot, newbornshoot'
  },
  '/discount-program-categories': {
    title: 'Alle categorieën - Familie voordeelprogramma',
    description:
      'Hier kun je de verschillende categorieën van het Familie Voordeel Programma ontdekken om jouw favoriete aanbiedingen te vinden!',
    tags: 'Familie Voordeel Programma, Familie Voordeel Programma aanbiedingen, categorieën Familie Voordeel Programma, babyspullen, gezinsuitstapjes, zwangerschapskleding, zwangerschapskorting, positiekleding, babykamer, babyuitzetlijst, gezinskorting, zwangerschapsbh, zwangerschapsshoot, newbornshoot'
  },
  '/alle-voordelen': {
    title: 'Alle voordelen - Familie voordeelprogramma',
    description: 'Bekijk hier alle voordelen van het Familie voordeelprogramma'
  },
  '/channels': {
    title: 'Kanalen Overzicht'
  },
  '/zwanger-worden': {
    title: 'Zwanger worden'
  },
  '/zwanger': {
    title: 'Zwanger'
  },
  '/bevalling': {
    title: 'Bevalling'
  },
  '/baby': {
    title: 'Baby'
  },
  '/zwangerschapscursus/welkom': {
    description:
      'Met deze 12 zwangerschapscursussen ben je helemaal voorzien! Hypnobirthing, borstvoedingscursus, zwangerschapsyoga en meer. keywords: zwangerschapscursus, bevalcursus, bevallingscursus, zwangerschapsyoga, online zwangerschapscursus, hypnobirthing cursus, hypnobirthing online, hypnobirthing, babymassage, slaapcursus, borstvoedingscursus, zwangerschapsyoga online, cursuspakket zwanger, cursussen zwanger, online cursuspakket zwanger, online zwangerschapscursus, cursusportaal, cursussen zwangerenportaal, cursuspakket zwangerenportaal'
  },
  '/zwangerschapscursus/zwangerschapscursus': {
    description:
      'Met deze online zwangerschapscursus leer je alles wat je moet weten over de groei van je baby, je gezondheid en controles tijdens de zwangerschap. keywords: zwangerschapscursus, zwangerschapscursus online, online zwangerschapscursus, online zwangerschapscursus volgen, cursus zwangerschap, zwangerschapscursus zwangerenportaal, zwangerschapscursus cursusportaal'
  },
  '/zwangerschapscursus/zwangerschapscursus/Introductievideo': {
    title:
      'Online Zwangerschapscursus | Zwangerschapscursus - Zwangerenportaal',
    description:
      'Begin goed voorbereid aan je zwangerschap met onze online zwangerschapscursus. Ontdek waardevolle informatie over babyontwikkeling, hormonen, onderzoeken en praktische tips voor een gezonde zwangerschap. Start hier om met vertrouwen aan je reis te beginnen!'
  },
  '/zwangerschapscursus/bevallingscursus': {
    description:
      'Met deze online bevallingscursus van 12 modules ga je voorbereid, kalm en zelfverzekerd je bevalling tegemoet, waar en hoe je dan ook gaat bevallen! keywords: bevallingscursus, cursus bevallen, bevalcursus, online bevalcursus, online bevallingscursus, bevallingscursus zwangerenportaal, bevalcursus zwangerenportaal, bevallingscursus cursusportaal, hypnobirthing cursus'
  },
  '/zwangerschapscursus/bevallingscursus/Introductievideobevalingscursus': {
    title: 'Online Bevallingscursus | Bevallingscursus - Zwangerenportaal',
    description:
      'Bereid je voor op de bevalling met onze online bevallingscursus. Ontdek praktische informatie over pijnbestrijding, weeën, de rol van de verloskundige en de eerste uren na de geboorte. Start hier voor een goed geïnformeerde en zelfverzekerde bevalling!'
  },
  '/zwangerschapscursus/borstvoedingscursus': {
    description:
      'Online een borstvoedingscursus volgen? Het kan bij zwangerenportaal! Leer over de voorbereiding, borstvoedingskwaaltjes, aanlegtechnieken en meer. keywords: borstvoedingscursus, online borstvoedingscursus, borstvoedingscursus online, borstvoedingscursus zwangerenportaal, borstvoedingscursus cursusportaal'
  },
  '/zwangerschapscursus/borstvoedingscursus/Introductievideo': {
    title:
      'Online Borstvoedingscursus | Borstvoedingscursus - Zwangerenportaal',
    description:
      'Bereid je voor op het geven van borstvoeding met onze online borstvoedingscursus. Leer meer over voedingssignalen, houdingen, de rol van een lactatiekundige en handige tips voor een succesvolle borstvoedingservaring. Start hier voor een zelfverzekerde en informatieve start!'
  },
  '/zwangerschapscursus/zwangerschapsyoga': {
    description:
      'Volg een cursus zwangerschapsyoga online! Op je eigen tempo en helemaal afgestemd op je zwangere lichaam, met talloze fijne yogaoefeningen. keywords: zwangerschapsyoga, cursus zwangerschapsyoga, zwangerschapsyoga online, zwangerschapsyoga cursus, zwangerschapsyoga zwangerenportaal, zwangerschapsyoga cursusportaal, zwangerschapsyoga volgen, zwangerschapsyoga oefeningen'
  },
  '/zwangerschapscursus/zwangerschapsyoga/Introductievideo': {
    title:
      'Online Cursus Zwangerschapsyoga | Zwangerschapsyoga - Zwangerenportaal',
    description:
      'Ontdek de voordelen van zwangerschapsyoga met onze online zwangeschapsyoga cursus. Leer ademhalingstechnieken, ontspanningsmethoden en houdingen die helpen bij comfort en welzijn tijdens je zwangerschap. Begin je yoga-reis hier en bereid je fysiek en mentaal voor op de bevalling!'
  },
  '/zwangerschapscursus/sporten-en-bewegen': {
    description:
      'Blijf lekker sporten tijdens de zwangerschap met deze cursus sporten en bewegen voor zwangeren! Ontdek welke sporten je mag doen en volg de fijne workouts. keywords: sporten zwanger, zwangerschapssporten, zwangerschapssport, zwangerschapsoefeningen, sporten tijdens zwangerschap, cursus sporten zwanger, zwangerschapsfitness, cursus zwangerschapsfitness, oefeningen tijdens zwangerschap, welke sporten zwanger, sporten zwangerenportaal, cursusportaal sporten, klachten sporten zwanger'
  },
  '/zwangerschapscursus/sporten-en-bewegen/Introductievideo': {
    title:
      'Online Cursus Sporten en Bewegen tijdens de Zwangerschap | Zwangerschapscursus - Zwangerenportaal',
    description:
      'Ontdek hoe je veilig en actief kunt blijven tijdens je zwangerschap met onze cursus over sporten en bewegen tijdens de zwangerschap. Leer over aangepaste oefeningen, voordelen van beweging, en tips om je fit en gezond te voelen. Start hier voor een energieke en gebalanceerde zwangerschap!'
  },
  '/zwangerschapscursus/babymassage': {
    description:
      'Met deze online ursus babymassage help je je baby te ontspannen, beter te slapen en kunnen jullie fijn hechten. Leer alle babymassage tips en tricks! keywords: babymassage, cursus babymassage, online cursus babymassage, online babymassage, babymassage tips, babymassage stappen, hoe baby masseren, cursus baby masseren, shantala babymassage, shantala massage, shantala baby masseren'
  },
  '/zwangerschapscursus/babymassage/Introductievideo': {
    title: 'Online cursus Babymassage | Zwangerschapscursus - Zwangerenportaal',
    description:
      'Leer de voordelen van babymassage met onze online cursus. Ontdek technieken die de binding bevorderen, helpen bij het ontspannen van je baby en bijdragen aan een betere nachtrust. Start hier om op een liefdevolle manier contact te maken met je pasgeborene!'
  },
  '/zwangerschapscursus/voedingscursus': {
    description:
      'Wat mag je allemaal wel en niet eten tijdens de zwangerschap? Leer alles over voeding tijdens de zwangerschap en voor je baby met deze cursus! keywords: voeding zwanger, eten zwanger, cursus voeding zwanger, wat mag je eten zwanger, wat mag je niet eten zwanger, cursus zwangerenportaal voeding, cursusportaal zwanger voeding, cursus voeding, cursus babyvoeding, vaste hapjes baby, vast voedsel baby cursus'
  },
  '/zwangerschapscursus/voedingscursus/Introductievideo': {
    title:
      'Online Voedingscursus voor Zwangeren | Voedingscursus - Zwangerenportaal',
    description:
      'Leer de basis van gezonde voeding tijdens de zwangerschap met onze online voedingscursus. Ontdek voedingsadvies, essentiële voedingsstoffen en tips voor een gebalanceerd dieet om zowel jou als je baby te ondersteunen. Start hier voor een gezonde en bewuste zwangerschap!'
  },
  '/zwangerschapscursus/snelcursus-voor-de-partner': {
    description:
      'Je partner is o zo belangrijk tijdens de zwangerschap. Volg deze online zwangerschapscursus en bevalcursus voor partners! keywords: bevalcursus partner, zwangerschapscursus partner, online partnercursus zwangerschap, online zwangerschcapscursus partner, online bevalcursus partner'
  },
  '/zwangerschapscursus/snelcursus-voor-de-partner/Introductievideo': {
    title:
      'Online Snelcursus voor de Partner | Zwangerschapscursus - Zwangerenportaal',
    description:
      'Bereid je als partner voor op de zwangerschap en bevalling met onze snelcursus voor de partner. Leer essentiële tips en inzichten om je partner te ondersteunen tijdens deze bijzondere periode. Start hier om een betrokken en ondersteunende partner te zijn!'
  },
  '/zwangerschapscursus/hypnobirthing': {
    description:
      'Volg deze online cursus Hypnobirthing en ga voorbereid, kalm en met een positieve mindset je bevalling tegemoet. keywords: hypnobirthing, online hypnobirthing, cursus hypnobirthing, cursus hypnobirthing online, hypnobirthing zwangerenportaal, hypnobirthing cursusportaal'
  },
  '/zwangerschapscursus/hypnobirthing/Introductievideo': {
    title: 'Online Hypnobirthing Cursus | Hypnobirthing - Zwangerenportaal',
    description:
      'Ontdek de kracht van hypnobirthing met onze online cursus hypnobirthing. Leer technieken voor ontspanning, ademhaling en visualisatie om de bevalling rustiger en zelfverzekerder te beleven. Begin hier je reis naar een positieve geboorte-ervaring!'
  },
  '/zwangerschapscursus/seksualiteit-tijdens-en-na-de-zwangerschap': {
    description:
      'Deze cursus seksualiteit tijdens de zwangerschap bereidt jou en je partner voor op je veranderende seksuele behoeften, ook na de bevalling. keywords: seksualiteit zwangerschap, seksualiteit na de zwangerschap, seksualiteit na bevalling, cursus seksualiteit zwangerschap, cursus seksualiteit na bevalling'
  },
  '/zwangerschapscursus/seksualiteit-tijdens-en-na-de-zwangerschap/Introductievideo':
    {
      title:
        'Online cursus Seksualiteit tijdens en na de Zwangerschap | Zwangerschapscursus - Zwangerenportaal',
      description:
        'Ontdek met onze cursus seksualiteit tijdens en na de zwangerschap hoe seksualiteit kan veranderen tijdens en na de zwangerschap. Krijg inzicht in lichamelijke en emotionele veranderingen, communicatie met je partner en tips om intimiteit te behouden. Start hier voor een open en gezonde benadering van seksualiteit tijdens deze levensfase!'
    },
  '/zwangerschapscursus/herstel-na-de-bevalling-sep': {
    description:
      'Bevorder je herstel na de bevalling met deze online postpartumcursus! Leer over je hormoonhuishouding, geschikte voeding, menstruatie en meer. keywords: cursus herstel na bevalling, postpartum cursus, cursus na bevalling, herstellen na bevalling, hormonen na bevalling, voeding na bevalling, menstruatie na bevalling, postnatale depletie'
  },
  '/zwangerschapscursus/herstel-na-de-bevalling-sep/Introductievideo': {
    title:
      'Online cursus Herstel na de Bevalling | Zwangerschapscursus - Zwangerenportaal',
    description:
      'Bereid je voor op een herstelperiode na de bevalling met onze online cursus herstel na de bevalling. Leer over het fysieke en emotionele herstel, herstelstrategieën, en het herstel van je energie. Ontvang waardevolle tips om het herstelproces te ondersteunen en goed voor jezelf te zorgen in deze nieuwe fase van het ouderschap.'
  },
  '/zwangerschapscursus/de-eerste-100-dagen-na-de-bevalling': {
    description:
      'In de online babycursus "De eerste 100 dagen na de bevalling" leer je alles over de verzorging van je baby, huilgedrag, ziektes, voeding en nog veel meer. keywords: cursus babyverzorging, cursus baby huilen, babycursus, eerste 100 dagen na bevalling cursus, babycursus zwangerenportaal, babycursus cursusportaal, cursus babyvoeding, cursus verzorging baby'
  },
  '/zwangerschapscursus/de-eerste-100-dagen-na-de-bevalling/Introductievideo': {
    title:
      'Online cursus Eerste 100 Dagen na de Bevalling | Zwangerschapscursus - Zwangerenportaal',
    description:
      'Bereid je voor op de eerste 100 dagen na de bevalling met onze online cursus. Leer over zorg voor je pasgeborene, herstel na de bevalling en hoe je deze nieuwe fase met vertrouwen en rust kunt doorkomen. Start hier met waardevolle inzichten voor de eerste dagen als ouder!'
  },
  '/zwangerschapscursus/dragen-en-hechting': {
    description:
      'Leer hoe je je baby kunt dragen in een draagzak of draagdoek met deze cursus dragen en hechting. Het dragen heeft zo veel voordelen! keywords: baby dragen, baby draagzak, baby draagdoek, hoe baby dragen in draagzak, hoe baby dragen in draagdoek, baby hechting draagzak, cursus baby dragen, draagcursus baby, cursus draagconsulent, online cursus draagconsulent, online draagcursus baby, online cursus baby dragen'
  },
  '/zwangerschapscursus/dragen-en-hechting/Introductievideo': {
    title:
      'Online cursus Dragen en Hechting | Zwangerschapscursus - Zwangerenportaal',
    description:
      'Leer hoe je de band met je baby kunt versterken door onze online cursus dragen en hechting. Ontdek de voordelen van huid-op-huid contact, draagtechnieken en hoe je je baby geruststelt. Begin hier voor praktische tips en ondersteuning in het creëren van een sterke, liefdevolle relatie met je pasgeborene!'
  },
  '/zwangerschapscursus/babysignalen-en-baby-en-kinder-gebaren': {
    description:
      'Met deze online cursus babygebaren en kindergebaren leer jij op een effectieve en speelse manier met je kind te communiceren, al op een vroege leeftijd! keywords: cursus babygebaren, cursus kindergebaren, kindergebaren leren, babygebaren leren, communiceren met gebaren kind, gebarentaal met kind, gebarentaal met baby, babygebaren zwangerenportaal, babygebaren cursusportaal, kindergebaren cursusportaal, kindergebaren zwangerenportaal'
  },
  '/zwangerschapscursus/babysignalen-en-baby-en-kinder-gebaren/Introductievideo':
    {
      title:
        'Online cursus Babysignalen en Baby- en Kindergebaren | Zwangerschapscursus - Zwangerenportaal',
      description:
        'Ontdek hoe je de signalen van je baby kunt begrijpen met onze online cursus over baby- en kindergebaren. Leer gebaren en non-verbale communicatie herkennen om de behoeften van je baby beter te begrijpen en te ondersteunen. Begin hier om de verbinding met je baby te versterken!'
    },
  '/zwangerschapscursus/slaapcursus0-6maanden/Introductievideo': {
    title:
      'Online Slaapcursus 0-6 Maanden | Zwangerschapscursus - Zwangerenportaal',
    description:
      'Leer hoe je de slaap van je baby tussen 0-6 maanden kunt verbeteren met onze slaapcursus. Ontdek praktische tips en technieken om een rustiger slaappatroon te bevorderen en zowel jij als je baby meer slaap te geven. Begin hier voor een gezonde slaaproutine!'
  },
  '/zwangerschapscursus/slaapcursus-pakket/Introductievideo': {
    title: 'Online Slaapcursus | Zwangerschapscursus - Zwangerenportaal',
    description:
      'Ontdek hoe je de slaap van je kindje tussen 6 maanden en 5 jaar oud kunt verbeteren met onze slaapcursus. Leer effectieve technieken en strategieën voor het verbeteren van de slaap van je baby, vanaf 6 maanden tot 5 jaar oud. Start hier voor een rustiger slaappatroon en meer nachtrust voor jou en je kindje!'
  }
};
