export default {
  DEFAULT_LOCALE: 'nl',
  HOST: 'zwangerenportaal.nl',
  GRAPHQL_URL: 'https://api.zwangerenportaal.nl',
  AUTH_URL: 'https://id.zwangerenportaal.nl/auth',
  LOGIN_URL: 'https://id.zwangerenportaal.nl/login',
  SIGNUP_URL: 'https://id.zwangerenportaal.nl/signup',
  PASSWORD_RESET_URL: 'https://id.zwangerenportaal.nl/password-reset',
  RETRIEVE_USERNAME_URL: 'https://id.zwangerenportaal.nl/retrieve-username',
  LOGOUT_URL: 'https://id.zwangerenportaal.nl/logout',
  PLAZA_VOUCHERS_URL: 'https://vouchers.zwangerenportaal.nl'
};
