export function getString(entry, condition) {
  if (typeof entry === 'string')
    return condition ? entry : undefined;

  return entry.reduce((values, item) => {
    if (item.condition) values.push(item.value);

    return values;
  }, []).join(' ');
}
