import { useMobile } from './hooks';

export default function sendEvent(...parameters) {
  if (!parameters[0]) return;

  if (window && window._paq) window._paq.push(['trackEvent', ...parameters]);
}

export function usePlatform() {
  const [isMobile] = useMobile();

  return isMobile ? 'mobile' : 'desktop';
}

export function trackAction(platform, page, action) {
  sendEvent(`${page}-${platform}`, `${page}-${platform}-${action}`, page);
}

export function sendDelayedEvent(...parameters) {
  setTimeout(() => {
    sendEvent(...parameters);
  }, 5000);
}
