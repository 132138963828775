import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import React, { useState, useEffect, useRef } from 'react';

import './Header.scss';
import { link } from '../../helpers/link';
import { defaultCoursesPage, isPartner } from '../../helpers/user';
import { getString } from '../../helpers/styles';
import { submitSearchForm } from '../../helpers/form';
import { removeItem } from '../../helpers/sessionStorage';
import sendEvent, { trackAction, usePlatform } from '../../helpers/tracking';

import Popup from '../redesign/Popup/Popup.jsx';

const Transition = dynamic(() => import('../Transition/Transition.jsx'));

const LoginBanner = dynamic(
  () => import('../redesign/home/LoginBanner/LoginBanner.jsx')
);

const UserInboxSidebar = dynamic(
  () => import('../redesign/UserInboxSidebar/UserInboxSidebar.jsx')
);

const LoginPopupContent = dynamic(
  () =>
    import('../redesign/PopupContent/LoginPopupContent/LoginPopupContent.jsx')
);

import User from '../../shared/assets/icons/redesign/user.svg';
import Bell from '../../shared/assets/icons/redesign/bell.svg';
import Logo from '../../shared/assets/icons/redesign/logo.svg';
import Menu from '../../shared/assets/icons/redesign/menu.svg';
import Search from '../../shared/assets/icons/redesign/search.svg';
import LogoSimple from '../../shared/assets/icons/redesign/logo-simple.svg';
import ChevronLeft from '../../shared/assets/icons/redesign/chevron-left.svg';
import CoursesLogoIcon from '../../shared/assets/icons/redesign/courses-logo.svg';

const trackingCodes = {
  zoeken: {
    homeDesktop: ['menu-home-desktop', 'menu-home-desktop-zoeken'],
    homeMobile: ['menu-home-mobile', 'menu-home-mobile-zoeken'],
    dashboardDesktop: ['dashboard-desktop', 'dashboard-desktop-search'],
    dashboardMobile: ['menu-dashboard-mobile', 'menu-dashboard-mobile-zoeken']
  },
  account: {
    homeDesktop: ['menu-home-desktop', 'menu-home-desktop-account'],
    homeMobile: ['menu-home-mobile', 'menu-home-mobile-account'],
    dashboardDesktop: ['dashboard-desktop', 'dashboard-desktop-profile'],
    dashboardMobile: ['dashboard-mobile', 'dashboard-mobile-profile']
  },
  hamburger: {
    homeDesktop: ['menu-home-desktop', 'menu-home-desktop-hamburger'],
    homeMobile: ['menu-home-mobile', 'menu-home-mobile-hamburger'],
    dashboardDesktop: ['dashboard-desktop', 'dashboard-desktop-hamburger'],
    dashboardMobile: ['dashboard-mobile', 'dashboard-mobile-hamburger'],
    partnerDashboardDesktop: [
      'dashboard-partner-desktop',
      'dashboard-partner-desktop-hamburger'
    ],
    partnerDashboardMobile: [
      'dashboard-partner-mobile',
      'dashboard-partner-mobile-hamburger'
    ]
  },
  login: {
    homeMobile: [
      'menu-home-mobile-hamburger',
      'menu-home-mobile-hamburger-inloggen'
    ],
    dashboardMobile: [
      'menu-dashboard-mobile-hamburger',
      'menu-dashboard-mobile-hamburger-inloggen'
    ]
  }
};

export default function Header(props) {
  const router = useRouter();
  const platform = usePlatform();
  const header = useRef(null);
  const submenu = useRef(null);

  const {
    menu,
    path,
    user,
    query,
    NextLink,
    superMenu,
    isHomePage,
    userSubscriptions
  } = props;

  const isDashboardPage =
    [
      '/dashboard/profile',
      '/profile-settings',
      '/bookmarks',
      '/saved-products',
      '/followed-channels',
      '/saved-checklists',
      '/files',
      '/appointments',
      '/shared-folders',
      '/video-call',
      '/rooms',
      '/inbox'
    ].includes(path) || path.startsWith('/channels/');
  const isCoursesPage = path.startsWith('/zwangerschapscursus/');

  const [loginPopup, showLoginPopup] = useState(false);
  const [inboxSidebar, showInboxSidebar] = useState(false);
  const [loginBanner, showLoginBanner] = useState(false);

  const [initialTab, initialSubTab] = getInitialTabs(menu);

  const [activeTab, changeTab] = useState(initialTab);
  const [activeSubTab, changeSubTab] = useState(initialSubTab);

  const [searchMode, toogleSearchMode] = useState(false);
  const [superMenuMode, toggleSuperMenu] = useState(false);

  const [isMobile, setIsMobile] = useState(false);
  const [isFirstVisit, setIsFirstVisit] = useState(false);

  const hasCourseInvoicesAccess = !!userSubscriptions?.length;

  const areas = getCustomAreas(path);

  const userNotificationCount = user.username
    ? props.userTasks.length + user.notifications.length
    : 0;

  useEffect(() => {
    setIsMobile(document.body.offsetWidth <= 850);
    if (!document.cookie.includes('_pk_id')) setIsFirstVisit(true);

    document.body.onclick = (e) => hideSuperMenu(e);

    const isWideSubmenu = submenu.current && submenu.current.dataset.wide;

    if (isWideSubmenu) header.current.classList.add('header_wide_submenu');
  }, []);

  useEffect(() => {
    if (loginBanner) showLoginBanner(false);

    const introBox = document.querySelector('.introBox');

    const listener = () =>
      displayLoginPopup(introBox.getBoundingClientRect().height);

    if (introBox) window.addEventListener('scroll', listener);
    return () => window.removeEventListener('scroll', listener);
  }, [user]);

  useEffect(() => {
    const userInboxSidebar = document.querySelector('.userInboxSidebar');

    if (userInboxSidebar)
      userInboxSidebar.style.top = `${
        document.getElementById('header').offsetHeight
      }px`;
  }, [activeTab, activeSubTab]);

  useEffect(() => {
    changeTab(initialTab);
    changeSubTab(initialSubTab);
  }, [initialTab, initialSubTab]);

  return (
    <React.Fragment>
      <div id="overlay"></div>

      <Transition on={loginPopup}>
        <div className="popup-wrapper">
          <Popup onClose={() => showLoginPopup(false)}>
            <LoginPopupContent
              loginUrl={props.loginUrl}
              signupUrl={props.signupUrl}
            />
          </Popup>
        </div>
      </Transition>

      <UserInboxSidebar
        user={user}
        NextLink={NextLink}
        taskList={props.userTasks}
        inboxSidebar={inboxSidebar}
        addUserEvent={props.addUserEvent}
        showInboxSidebar={showInboxSidebar}
        notificationList={user.notifications}
        refetchUserData={props.refetchUserData}
        markNotificationRead={props.markNotificationRead}
      />

      <header
        id="header"
        ref={header}
        className={getString([
          {
            value: 'header_supermenu_visible',
            condition: superMenuMode
          },
          {
            value: 'header_without_submenu',
            condition: !activeTab || !activeTab.submenu
          },
          {
            value: 'header_menu_bar_hidden',
            condition: user.username && isDashboardPage
          }
        ])}
      >
        <div id="header_divider" className="header_mobile"></div>

        <section
          id="header_main_bar"
          className={getString('header_search_mode', searchMode)}
        >
          {areas?.left ? (
            <div className="header_left_area">{areas.left}</div>
          ) : (
            <div className="header_mobile">
              <div
                id="header_menu_icon"
                onClick={() => {
                  sendEvent(...getTrackingCodes('hamburger', user, path));

                  showSuperMenu();
                }}
              >
                <Menu />
              </div>

              <Logo id="header_logo" onClick={() => router.push('/')} />
            </div>
          )}

          {!areas?.left && (
            <Logo id="header_logo" onClick={() => router.push('/')} />
          )}

          <nav id="header_menu">
            {areas?.center ? (
              <div className="header_center_area">{areas.center}</div>
            ) : (
              <ul>
                {menu.map((item, idx) => {
                  const discountProgramItem = item.link === '/voordeel';

                  const itemLink = discountProgramItem
                    ? user.premium
                      ? '/alle-voordelen'
                      : '/voordeel'
                    : item.link;

                  return (
                    <li
                      key={idx}
                      data-label={item.label}
                      className={getString('header_desktop', item.desktopLink)}
                    >
                      <div
                        onClick={(e) => {
                          changeActiveTab(e.target, menu);
                          sendEvent(
                            ...(item.tracking[getTrackingKey(user, path)] || [])
                          );
                        }}
                        className={getString(
                          'header_active_tab',
                          activeTab && item.label === activeTab.label
                        )}
                      >
                        {link(<span>{item.label}</span>, itemLink, NextLink)}
                      </div>
                    </li>
                  );
                })}
              </ul>
            )}
          </nav>

          <div id="header_search_form" onTransitionEnd={() => setFocus()}>
            <form onSubmit={(e) => submitSearchForm(e, 'header')}>
              <input
                name="search"
                autoComplete="off"
                placeholder="Zoeken..."
                id="header_search_form_input"
              />
              <span onClick={() => toogleSearchMode(!searchMode)}>&times;</span>
            </form>
          </div>

          <ul id="header_icons">
            <li
              id="header_search_icon"
              onClick={() => {
                sendEvent(...getTrackingCodes('zoeken', user, path));

                if (!searchMode) toogleSearchMode(!searchMode);
              }}
            >
              <Search />
            </li>

            <li
              className="header-notifications-icon-wrap"
              onClick={() => {
                if (!user.username) return showLoginPopup(true);

                if (isMobile) router.push('/inbox#notifications');
                else showInboxSidebar(true);
              }}
            >
              <Bell className="header-notifications-icon" />
              {!(user.username && !userNotificationCount) && (
                <span
                  className={getString([
                    {
                      value: 'header-notifications-count',
                      condition: true
                    },
                    {
                      value: 'header-notifications-count-up',
                      condition: user.username && userNotificationCount > 99
                    }
                  ])}
                >
                  {user.username ? userNotificationCount : 1}
                </span>
              )}
            </li>

            <li
              id="header_user_icon"
              onClick={() => {
                sendEvent(...getTrackingCodes('account', user, path));
                removeItem('originalUrl');
                if (!user.username) {
                  showLoginPopup(true);
                }
              }}
            >
              {user.username ? (
                <a href="/dashboard">
                  {user.profilePicture ? (
                    <img src={user.profilePicture} alt="user-image" />
                  ) : (
                    <span>
                      <User />
                    </span>
                  )}
                </a>
              ) : (
                <span
                  onClick={() => {
                    showLoginPopup(true);
                  }}
                >
                  <User />
                </span>
              )}
            </li>

            <li
              id="header_menu_icon"
              className="header_desktop"
              onClick={() => {
                sendEvent(...getTrackingCodes('hamburger', user, path));

                showSuperMenu();
              }}
            >
              <Menu />
            </li>
          </ul>
        </section>

        {activeTab?.submenu && !isCoursesPage && (
          <nav
            id="header_submenu"
            ref={submenu}
            {...(activeTab.submenu.length > 8 && { 'data-wide': true })}
          >
            <ul>
              {activeTab.submenu.map((item, idx) => {
                const itemLink =
                  activeTab.label === 'Magazine'
                    ? `${activeTab.link}?tab=${item.link}`
                    : `${item.link}`;

                if (user.premium && item.link === '/voordeel') return null;

                return item.loggedIn && !user.username ? null : (
                  <li
                    key={idx}
                    data-label={item.label}
                    onClick={(e) => {
                      changeActiveSubTab(e.target, activeTab.submenu);

                      if (item.eventTrackingCodes)
                        sendEvent(...item.eventTrackingCodes);
                    }}
                    className={getString(
                      'header_active_subtab',
                      activeSubTab && item.label === activeSubTab.label
                    )}
                  >
                    {link(<span>{item.label}</span>, itemLink, NextLink)}
                  </li>
                );
              })}
            </ul>
          </nav>
        )}

        <section id="header_supermenu">
          <div className="header_mobile header-supermenu-header-mobile">
            <span
              className="header-supermenu-close-button"
              onClick={() => toggleSuperMenu(false)}
            >
              &times;
            </span>

            {!user.username && (
              <p
                onClick={() =>
                  sendEvent(...getTrackingCodes('login', user, path))
                }
              >
                <a href={props.loginUrl}>
                  <span>Inloggen</span>
                </a>{' '}
                {'of '}
                <a href={props.signupUrl}>
                  <span>registreren</span>
                </a>
              </p>
            )}
          </div>

          <ul id="header_supermenu_columns">
            {superMenu.map((column, idx) => (
              <li
                key={idx}
                data-label={column.label}
                className={getString([
                  {
                    value: 'header_supermenu_column',
                    condition: true
                  },
                  {
                    value: 'header_supermenu_column_mobile',
                    condition: column.isMobile
                  }
                ])}
              >
                <NextLink href={column.link} prefetch={false}>
                  <p
                    onClick={() => {
                      toggleSuperMenu(false);
                      document
                        .getElementById('overlay')
                        .classList.remove('overlay_active');

                      sendEvent(...(column.tracking[getTrackingKey()] || []));
                    }}
                  >
                    {column.label}
                  </p>
                </NextLink>

                <ul>
                  {column.menu.map((item, idx) => (
                    <li
                      key={idx}
                      data-label={item.label}
                      onClick={() => {
                        toggleSuperMenu(false);
                        document
                          .getElementById('overlay')
                          .classList.remove('overlay_active');

                        sendEvent(...(item.tracking[getTrackingKey()] || []));
                      }}
                    >
                      <NextLink href={item.link} prefetch={false}>
                        <span>{item.label}</span>
                      </NextLink>
                    </li>
                  ))}
                </ul>
              </li>
            ))}
          </ul>
        </section>

        {!user.username && (
          <Transition on={loginBanner} className="loginBanner">
            <LoginBanner
              isFirstVisit={isFirstVisit}
              loginUrl={props.loginUrl}
              signupUrl={props.signupUrl}
            />
          </Transition>
        )}
      </header>
    </React.Fragment>
  );

  function displayLoginPopup(height) {
    if (window.scrollY > height && !loginBanner) showLoginBanner(true);
    else showLoginBanner(false);
  }

  function changeActiveTab(el, menu) {
    const newTab = el.closest('li').dataset.label;

    const tab = menu.find((item) => item.label === newTab);

    changeTab(tab);

    return tab;
  }

  function changeActiveSubTab(el, submenu) {
    if (!submenu) return;

    const newTab = el.closest('li').dataset.label;

    changeSubTab(submenu.find((item) => item.label === newTab));
  }

  function showSuperMenu() {
    if (document.body.offsetWidth <= 850) window.scrollTo(0, 0);

    toggleSuperMenu(!superMenuMode);

    setOverlay();

    showInboxSidebar(false);
  }

  function hideSuperMenu(e) {
    if (
      !e ||
      e.target.closest('#header_supermenu') ||
      e.target.closest('#header_menu_icon')
    )
      return;

    document.getElementById('overlay')?.classList.remove('overlay_active');

    toggleSuperMenu(false);
  }

  function setOverlay() {
    if (document.body.offsetWidth <= 850) return;

    document.getElementById('overlay').classList.toggle('overlay_active');

    const mainBarHeight =
      document.getElementById('header_main_bar').offsetHeight;
    const superMenuHeight =
      document.getElementById('header_supermenu').offsetHeight;

    document.getElementById('overlay').style.top = `${
      mainBarHeight + superMenuHeight
    }px`;
  }

  function setFocus() {
    document.getElementById('header_search_form_input').focus();
  }

  function getInitialTabs(menu) {
    const tab = getInitialTab(menu, path, query);

    if (!tab) {
      const tab = menu.find((item) => item.link === path);

      if (!tab) return [];

      const subTab = tab.defaultSubtab && tab.submenu[0];

      return [tab, subTab];
    }

    const subTab = getInitialSubTab(tab, path, query);

    return [tab, subTab];
  }

  function getInitialTab(menu, path, query) {
    if (isDashboardPage) return menu.find((item) => item.link === '/dashboard');

    return menu.find(
      (item) =>
        item.submenu &&
        item.submenu.find(
          (item) =>
            [path, query.tab].includes(item.link) || path.includes(item.alias)
        )
    );
  }

  function getInitialSubTab(tab, path, query) {
    return (
      tab.submenu &&
      (tab.submenu.find(
        (item) =>
          [path, query.tab].includes(item.link) || path.includes(item.alias)
      ) ||
        tab.submenu.find((item) => item.defaultSubTab))
    );
  }

  function getTrackingKey(user = {}, path) {
    const isDashboardHomePage = path === '/dashboard';

    if (isHomePage)
      if (isMobile) return 'homeMobile';
      else return 'homeDesktop';
    if (isDashboardHomePage)
      if (isPartner(user)) return 'partnerDashboardDesktop';
      else if (isMobile) return 'dashboardMobile';
      else return 'dashboardDesktop';
    else return null;
  }

  function getTrackingCodes(item, user, path) {
    return (
      (trackingCodes[item] &&
        trackingCodes[item][getTrackingKey(user, path)]) ||
      []
    );
  }

  function getCustomAreas(uri) {
    return [
      {
        paths: ['/zwangerschapscursussen/test'],
        areas: {
          center: (
            <ul>
              <li
                onClick={() => router.push('/zwangerschapscursussen/test')}
                className="header_custom_submenu_item header_courses_submenu_item_active"
              >
                Cursussen
              </li>
            </ul>
          ),
          left: (
            <>
              <a href="/zwangerschapscursussen/test">
                <ChevronLeft className="header_left_area-back-icon" />
              </a>

              <Title
                name="Cursussen"
                user={user}
                path="/zwangerschapscursussen/test"
              />
            </>
          )
        }
      },
      {
        paths: [
          '/duurzaam/babyuitzetlijst',
          '/meer-leren-over-duurzaamheid-in-het-ouderschap'
        ],
        areas: {
          left: (
            <>
              <a href="/dashboard">
                <ChevronLeft className="header_left_area-back-icon" />
              </a>

              <a href="/dashboard">Duurzaamheid</a>
            </>
          ),
          center: (
            <ul>
              <li
                {...(uri ===
                  '/meer-leren-over-duurzaamheid-in-het-ouderschap' && {
                  className: 'header-link-active'
                })}
                onClick={() =>
                  (location.href =
                    '/meer-leren-over-duurzaamheid-in-het-ouderschap')
                }
              >
                Leren
              </li>

              <li
                {...(uri.startsWith('/duurzaam/babyuitzetlijst') && {
                  className: 'header-link-active'
                })}
                onClick={() => (location.href = '/duurzaam/babyuitzetlijst')}
              >
                Huren
              </li>

              <li>Supporten</li>
            </ul>
          )
        }
      },
      {
        paths: ['/zwangerschapscursus/welkom-s1', '/het-cursusportaal-s1'],
        areas: {
          center: (
            <ul>
              {!user.hasCourseAccess && (
                <li
                  onClick={() => router.push('/zwangerschapscursus/welkom')}
                  className={getString([
                    {
                      value: 'header_courses_submenu_item',
                      condition: true
                    },
                    {
                      value: 'header_courses_submenu_item_active',
                      condition: uri.startsWith(
                        '/zwangerschapscursus/welkom-s1'
                      )
                    }
                  ])}
                >
                  Cursusportaal
                </li>
              )}

              <li
                onClick={() => {
                  router.push('/het-cursusportaal-s1');

                  trackAction(
                    platform,
                    'courseswelkom',
                    'clickmoreinformationbutton'
                  );
                }}
                className={getString([
                  {
                    value: 'header_courses_submenu_item',
                    condition: true
                  },
                  {
                    value: 'header_courses_submenu_item_active',
                    condition: uri.startsWith('/het-cursusportaal-s1')
                  }
                ])}
              >
                Meer informatie
              </li>
            </ul>
          ),
          left: (
            <>
              <a href="/">
                <ChevronLeft className="header_left_area-back-icon" />
              </a>

              <a
                href={
                  user.hasCourseAccess
                    ? '/zwangerschapscursus/overzicht'
                    : '/zwangerschapscursus/welkom-s1'
                }
              >
                <div className="header_courses_logo">
                  <CoursesLogoIcon className="header_courses_logo_icon" />
                  <div className="header_courses_logo_text">Cursusportaal</div>
                </div>
              </a>
            </>
          )
        }
      },
      {
        paths: [
          '/zwangerschapscursus/welkom',
          '/zorgverzekeraars-die-onze-online-cursussen-wel-en-niet-vergoeden',
          '/10-zwangerschaps-en-bevalcursussen-onbeperkt-en-online-bij-zwangerenportaalnl'
        ],
        areas: {
          center: (
            <ul>
              <li
                onClick={() => router.push(defaultCoursesPage(user))}
                className={getString([
                  {
                    value: 'header_custom_submenu_item',
                    condition: true
                  },
                  {
                    value: 'header_courses_submenu_item_active',
                    condition: uri.startsWith('/zwangerschapscursus/welkom')
                  }
                ])}
              >
                Zwanger
              </li>

              <li
                onClick={() =>
                  router.push('/zwangerschapscursus/ouder-en-kind')
                }
                className={getString([
                  {
                    value: 'header_custom_submenu_item',
                    condition: true
                  },
                  {
                    value: 'header_courses_submenu_item_active',
                    condition: uri.startsWith(
                      '/zwangerschapscursus/ouder-en-kind'
                    )
                  }
                ])}
              >
                Ouder en kind
              </li>

              {!user.hasCourseAccess && (
                <li
                  onClick={() =>
                    router.push(
                      '/10-zwangerschaps-en-bevalcursussen-onbeperkt-en-online-bij-zwangerenportaalnl'
                    )
                  }
                  className={getString([
                    {
                      value: 'header_custom_submenu_item',
                      condition: true
                    },
                    {
                      value: 'header_courses_submenu_item_active',
                      condition: uri.startsWith(
                        '/10-zwangerschaps-en-bevalcursussen-onbeperkt-en-online-bij-zwangerenportaalnl'
                      )
                    }
                  ])}
                >
                  Meer informatie
                </li>
              )}
            </ul>
          ),
          left: (
            <>
              <a
                href={
                  uri === '/zwangerschapscursus/welkom' ? '/' : '/dashboard'
                }
              >
                <ChevronLeft className="header_left_area-back-icon" />
              </a>

              <Title name="Cursussen" user={user} />
            </>
          )
        }
      },
      {
        paths: ['/zwangerschapscursus/ouder-en-kind'],
        areas: {
          center: (
            <ul>
              {!user.hasCourseAccess && (
                <li
                  onClick={() => router.push('/zwangerschapscursus/welkom')}
                  className={getString([
                    {
                      value: 'header_custom_submenu_item',
                      condition: true
                    },
                    {
                      value: 'header_courses_submenu_item_active',
                      condition: uri.startsWith('/zwangerschapscursus/welkom')
                    }
                  ])}
                >
                  Zwanger
                </li>
              )}

              <li
                onClick={() =>
                  router.push('/zwangerschapscursus/ouder-en-kind')
                }
                className={getString([
                  {
                    value: 'header_custom_submenu_item',
                    condition: true
                  },
                  {
                    value: 'header_courses_submenu_item_active',
                    condition: uri.startsWith(
                      '/zwangerschapscursus/ouder-en-kind'
                    )
                  }
                ])}
              >
                Ouder en kind
              </li>

              {!user.hasCourseAccess && (
                <li
                  onClick={() =>
                    router.push(
                      '/10-zwangerschaps-en-bevalcursussen-onbeperkt-en-online-bij-zwangerenportaalnl'
                    )
                  }
                  className={getString([
                    {
                      value: 'header_custom_submenu_item',
                      condition: true
                    },
                    {
                      value: 'header_courses_submenu_item_active',
                      condition: uri.startsWith(
                        '/10-zwangerschaps-en-bevalcursussen-onbeperkt-en-online-bij-zwangerenportaalnl'
                      )
                    }
                  ])}
                >
                  Meer informatie
                </li>
              )}

              {hasCourseInvoicesAccess && (
                <li
                  onClick={() =>
                    router.push('/zwangerschapscursus/instellingen')
                  }
                  className={getString([
                    {
                      value: 'header_custom_submenu_item',
                      condition: true
                    },
                    {
                      value: 'header_courses_submenu_item_active',
                      condition: uri.startsWith(
                        '/zwangerschapscursus/instellingen'
                      )
                    }
                  ])}
                >
                  Instellingen
                </li>
              )}
            </ul>
          ),
          left: (
            <>
              <a
                href={
                  uri === '/zwangerschapscursus/welkom' ? '/' : '/dashboard'
                }
              >
                <ChevronLeft className="header_left_area-back-icon" />
              </a>

              <Title name="Cursussen" user={user} />
            </>
          )
        }
      },
      {
        paths: [
          '/zwangerschapscursus/overzicht',
          '/zwangerschapscursus/instellingen'
        ],
        areas: {
          center: (
            <ul>
              <li
                onClick={() =>
                  router.push('/zwangerschapscursus/ouder-en-kind')
                }
                className={getString([
                  {
                    value: 'header_custom_submenu_item',
                    condition: true
                  },
                  {
                    value: 'header_courses_submenu_item_active',
                    condition: uri.startsWith(
                      '/zwangerschapscursus/ouder-en-kind'
                    )
                  }
                ])}
              >
                Ouder en kind
              </li>

              {hasCourseInvoicesAccess && (
                <li
                  onClick={() =>
                    router.push('/zwangerschapscursus/instellingen')
                  }
                  className={getString([
                    {
                      value: 'header_custom_submenu_item',
                      condition: true
                    },
                    {
                      value: 'header_courses_submenu_item_active',
                      condition: uri.startsWith(
                        '/zwangerschapscursus/instellingen'
                      )
                    }
                  ])}
                >
                  Instellingen
                </li>
              )}
            </ul>
          ),
          left: (
            <>
              <a href="/dashboard">
                <ChevronLeft className="header_left_area-back-icon" />
              </a>

              <Title name="Cursussen" user={user} />
            </>
          )
        }
      },
      {
        paths: ['/zwangerschapscursus'],
        areas: {
          center: (
            <ul>
              <li
                onClick={() => router.push(defaultCoursesPage(user))}
                className="header_custom_submenu_item header_courses_submenu_item_active"
              >
                Cursussen
              </li>

              {hasCourseInvoicesAccess && (
                <li
                  className="header_custom_submenu_item"
                  onClick={() =>
                    router.push('/zwangerschapscursus/instellingen')
                  }
                >
                  Instellingen
                </li>
              )}
            </ul>
          ),
          left: (
            <>
              <a href={defaultCoursesPage(user)}>
                <ChevronLeft className="header_left_area-back-icon" />
              </a>

              <Title name="Cursussen" user={user} />
            </>
          )
        }
      },
      {
        paths: ['/community'],
        areas: {
          center: (
            <ul>
              <li
                onClick={() =>
                  props.defaultCommunityGroup?.uri &&
                  router.push(`/community/${props.defaultCommunityGroup.uri}`)
                }
                className={getString([
                  {
                    value: 'header_custom_submenu_item',
                    condition: true
                  },
                  {
                    value: 'header_community_submenu_item_active',
                    condition: !uri.startsWith('/community/discover-groups')
                  }
                ])}
              >
                Mijn groepen
              </li>

              <li
                className={getString([
                  {
                    value: 'header_custom_submenu_item',
                    condition: true
                  },
                  {
                    value: 'header_community_submenu_item_active',
                    condition: uri.startsWith('/community/discover-groups')
                  }
                ])}
                onClick={() => router.push('/community/discover-groups')}
              >
                Ontdek groepen
              </li>
            </ul>
          ),
          left: (
            <>
              <span onClick={() => router.back()}>
                <ChevronLeft className="header_left_area-back-icon" />
              </span>

              <Title
                name="Community"
                user={user}
                Icon={LogoSimple}
                path={
                  props.defaultCommunityGroup?.uri
                    ? `/community/${props.defaultCommunityGroup.uri}`
                    : '/community/onboarding'
                }
              />
            </>
          )
        }
      }
    ].find((item) => item.paths.find((path) => uri.startsWith(path)))?.areas;
  }

  function Title({ name, user, path, Icon }) {
    return (
      <div className="header_courses_logo">
        <a href={path || defaultCoursesPage(user)}>
          {Icon ? (
            <Icon className="header_courses_logo_icon" />
          ) : (
            <CoursesLogoIcon className="header_courses_logo_icon" />
          )}
          <div className="header_courses_logo_text">{name}</div>
        </a>
      </div>
    );
  }
}
