import * as Sentry from '@sentry/browser';
import { onError } from '@apollo/client/link/error';
import { ApolloClient, InMemoryCache, HttpLink, from } from '@apollo/client';
import config from '../config';

Sentry.init({ dsn: process.env.SENTRY_DSN });

const isBrowser = typeof window !== 'undefined';

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) => {
      Sentry.captureException(
        `[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(locations)}, Path: ${path}`
      );
    });
  }
  if (networkError) {
    Sentry.captureException(`[Network error]: ${networkError}`);
  }
});

const createApolloClient = (initialState = {}) => {
  const { jwt } = initialState;

  const httpLink = new HttpLink({
    uri: config.GRAPHQL_URL,
    credentials: 'same-origin',
    headers: jwt ? { 'X-Authorization': `Bearer ${jwt}` } : {}
  });

  return new ApolloClient({
    link: from([errorLink, httpLink]),
    cache: new InMemoryCache().restore(initialState),
    connectToDevTools: isBrowser,
    ssrMode: !isBrowser
  });
};

let apolloClient = null;

export default (initialState) => {
  if (!isBrowser) {
    return createApolloClient(initialState);
  }
  if (!apolloClient) {
    apolloClient = createApolloClient(initialState);
  }
  return apolloClient;
};
