import { setItem } from './localStorage';

export function isPregnant(user) {
  return user.status === 'PREGNANT';
}

export function isDelivered(user) {
  return user.status === 'DELIVERED';
}

export function isPartner(user) {
  return user.accountType === 'PARTNER';
}

export function isEnded(user) {
  return user.status === 'ENDED';
}

export function isOther(user) {
  return user.accountType === 'OTHER';
}

export function hasProviderFilesAccess(user) {
  return (
    hasAutoEddStatus(user) ||
    (isPregnant(user) && user.edd) ||
    isDelivered(user) || isEnded(user)
  );
}

export function hasAutoEddStatus(user) {
  return user.eddStatus === 'AUTO' && !user.edd;
}

export function isCommunityGroupMember({ members, accountType }, user) {
  return (
    members.some((member) => member.username === user.username) &&
    (isPartner(user) ? accountType === 'PARTNER' : accountType !== 'PARTNER')
  );
}

export function defaultCommunityGroup(user, groups = []) {
  const userGroups = groups.filter((group) =>
    group.members.find((member) => member.username === user.username)
  );

  const groupWithUserPhase = userGroups.find(({ journeyPhases }) =>
    journeyPhases.find(({ journeyPhase }) => journeyPhase.name === user.phase)
  );

  if (groupWithUserPhase) return groupWithUserPhase;

  const groupWithRecentActivity = userGroups.reduce((targetGroup, group) => {
    if (!targetGroup) return group;

    const targetGroupDate =
      !!targetGroup.topics.length &&
      new Date(
        Math.max.apply(
          null,
          targetGroup.topics.map(({ createdAt }) => new Date(createdAt))
        )
      );

    const groupDate =
      !!group.topics.length &&
      new Date(
        Math.max.apply(
          null,
          group.topics.map(({ createdAt }) => new Date(createdAt))
        )
      );

    if (targetGroupDate > groupDate) return targetGroup;
    else return group;
  }, null);

  return groupWithRecentActivity;
}

export function communityGroupRecentActivityDate(group) {
  const topicsRecentActivityDates = group.topics.map((topic) => {
    const topicMostRecentCommentDate = topic.events.length
      ? new Date(
          Math.max.apply(
            null,
            topic.events.map(
              ({ createdAt, updatedAt }) => new Date(updatedAt || createdAt)
            )
          )
        )
      : new Date(topic.createdAt);

    return new Date(topicMostRecentCommentDate);
  });

  return (
    !!topicsRecentActivityDates.length &&
    new Date(Math.max.apply(null, topicsRecentActivityDates))
  );
}

export function defaultCoursesPage(user) {
  if (isDelivered(user)) return '/zwangerschapscursus/ouder-en-kind';
  if (user.hasCourseAccess) return '/zwangerschapscursus/overzicht';
  return '/zwangerschapscursus/welkom';
}

export function openCommunityPage(user, group, config) {
  const url = `/community/onboarding?groupXuid=${group.xuid}`;

  setItem('originalUrl', url);

  location.href = !user.username
    ? config.LOGIN_URL
    : isCommunityGroupMember(group, user)
    ? `/community/${group.uri}`
    : url;
}

export function getCommunityMemberCount(groups) {
  return (groups || []).reduce((set, group) => {
    group.members.forEach((member) => set.add(member.username));

    return set;
  }, new Set()).size;
}

export function canEditContent(user) {
  // List of users specified in https://team.linkorb.com/cards/8526
  return [
    'lindseyburchell',
    'carel',
    'maarjescheffer',
    'roel',
    'raizorlol'
  ].includes(user.username);
}

export function getActiveSubscriptions(userSubscriptions = []) {
  return userSubscriptions.filter(({ endAt }) => new Date() < new Date(endAt));
}
