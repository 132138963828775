import React from 'react';

import './Popup.scss';

export default function Popup({ children, onClose }) {
  return (
    <div className="popup">
      <div className="popup-content">
        {React.cloneElement(children, { onClose })}
      </div>
    </div>
  );
}
