import { getDataFromTree } from '@apollo/client/react/ssr';
import initApollo from './initApollo';
import { ApolloProvider } from '@apollo/client';

const withApollo = (App) => {
  const Apollo = ({ apolloState, jwt, ...props }) => {
    const apolloClient = initApollo({ initialState: apolloState, jwt });

    return (
      <ApolloProvider client={apolloClient}>
        <App {...props} pageProps={{ ...props.pageProps, apolloClient }} />
      </ApolloProvider>
    );
  };

  Apollo.getInitialProps = async (ctx) => {
    const appProps = App.getInitialProps ? await App.getInitialProps(ctx) : {};

    const serverProps = ctx.Component.getServerSideProps
      ? await ctx.Component.getServerSideProps(ctx.ctx)
      : {};

    const jwt =
      ctx.ctx.query.jwt ||
      parseCookies(ctx.ctx.req && ctx.ctx.req.headers.cookie);

    const apolloClient = initApollo({ jwt });

    if (typeof window === 'undefined') {
      try {
        await getDataFromTree(
          <ctx.AppTree
            pageProps={{
              ...appProps,
              ...serverProps.props,
              apolloClient,
              Component: ctx.Component
            }}
          />
        );
      } catch (error) {
        console.error('Error while running `getDataFromTree`', error);
      }
    }

    const apolloState = apolloClient.cache.extract();

    return {
      ...appProps,
      ...serverProps.props,
      apolloState,
      jwt
    };
  };

  return Apollo;
};

function parseCookies(cookies) {
  if (!cookies) return null;

  const cookie = cookies
    .split('; ')
    .find((item) => item.split('=')[0] === 'jwt');

  return cookie ? cookie.split('=')[1] : null;
}

export default withApollo;
