import React from 'react';
import config from '../../config';

export function link(element, href = '/', NextLink) {
  if (NextLink)
    return <NextLink prefetch={false} href={href}>{element}</NextLink>;

  return (
    <a
      {...(isExternalLink(href) &&
        { target: '_blank',
          rel: 'noopener noreferrer'
        })}

      href={href}>
      {element}
    </a>
  );
}

export function isExternalLink(link) {
  return link && !link.startsWith('/') && !link.includes(config.domain);
}
