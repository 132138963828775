import { useState, useEffect } from 'react';

export function useMobile() {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (document.body.offsetWidth <= 1200) setIsMobile(true);
  }, []);

  return [isMobile, setIsMobile];
}

export function isMobile(ctx) {
  const userAgent = ctx.req.headers['user-agent'] || '';
  return /Android|iPhone|iPad|iPod|Opera Mini|IEMobile|Mobile/i.test(userAgent);
}
