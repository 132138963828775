import '../shared/common.scss';

import React from 'react';
import Link from 'next/link';
import Head from 'next/head';
import dynamic from 'next/dynamic';
import * as Sentry from '@sentry/browser';
import { useState, useEffect } from 'react';
import Router, { useRouter } from 'next/router';
import contentMetadata from '../config/metadata';
import { useUserTasks } from '../lib/hooks/userTasks';
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import { useQuery, ApolloProvider, useLazyQuery } from '@apollo/client';
import { defaultCommunityGroup } from 'plaza-react-components/src/helpers/user';

import config from '../config';
import withApollo from '../lib/withApollo';
import Preloader from '../shared/preloader';
import user from '../requests/queries/user';
import header from '../config/components/header';
import footer from '../config/components/footer';
import userGroups from '../requests/queries/userGroups';
import metaByPath from '../requests/queries/metaByPath';
import { useTrackingMutations } from '../lib/hooks/tracking';
import { useSetUserPhotoMutation } from '../lib/hooks/userData';
import popularChannels from '../requests/queries/popularChannels';
import { useAddUserEventMutation } from '../lib/hooks/userEvents';
import { setItem, getItem, removeItem } from '../lib/localStorage';
import { useMarkNotificationReadMutation } from '../lib/hooks/notifications';

import {
  useSubscribeChannelMutation,
  useUnsubscribeChannelMutation
} from '../lib/hooks/channels';
import {
  useAddWishlistMutationMutation,
  useRemoveWishlistMutationMutation
} from '../lib/hooks/wishlist';

import Header from 'plaza-react-components/src/components/Header/Header.jsx';
import userSubscriptionsQuery from '../requests/queries/userSubscriptions';

const Transition = dynamic(
  () =>
    import('plaza-react-components/src/components/Transition/Transition.jsx')
);
const Footer = dynamic(
  () =>
    import('plaza-react-components/src/components/redesign/Footer/Footer.jsx')
);
const LoginPopup = dynamic(
  () =>
    import('plaza-react-components/src/components/LoginPopup/LoginPopup.jsx')
);
const MessagePopup = dynamic(
  () =>
    import(
      'plaza-react-components/src/components/MessagePopup/MessagePopup.jsx'
    )
);
const UserPhotoPopup = dynamic(
  () =>
    import(
      'plaza-react-components/src/components/UserPhotoPopup/UserPhotoPopup.jsx'
    )
);
const BottomMenu = dynamic(
  () =>
    import(
      'plaza-react-components/src/components/redesign/BottomMenu/BottomMenu.jsx'
    )
);
const ChannelListPopup = dynamic(
  () =>
    import(
      'plaza-react-components/src/components/ChannelListPopup/ChannelListPopup.jsx'
    )
);
const DiscountProgramPopup = dynamic(
  () =>
    import(
      'plaza-react-components/src/components/DiscountProgramPopup/DiscountProgramPopup.jsx'
    )
);
const EmailPreferenciesPopup = dynamic(
  () =>
    import(
      'plaza-react-components/src/components/EmailPreferenciesPopup/EmailPreferenciesPopup.jsx'
    )
);

Sentry.init({ dsn: process.env.SENTRY_DSN });

const privatePaths = [
  '/files',
  '/inbox',
  '/rooms',
  '/profile',
  '/bookmarks',
  '/dashboard',
  '/onboarding',
  '/video-call',
  '/appointments',
  '/shared-folders',
  '/saved-products',
  '/change-password',
  '/profile-settings',
  '/saved-checklists',
  '/cursussen/betalen',
  '/followed-channels',
  '/dashboard/profile',
  '/onboarding-redesign',
  '/deskundigen-in-de-buurt',
  '/discount-program-register',
  '/zwangerschapscursus/betalen',
  '/zwangerschapscursus/instellingen',
  '/zwangerschapscursus/betalen-ideal',
  '/zwangerschapscursus/betalen/gelukt',
  '/zwangerschapscursus/betalen/verzekering',
  '/verzekering/cursus-vergoeding',
  '/verzekering/cursus-vergoeding-aanvullend',
  '/community/discover-groups'
];

const dashboardPages = [
  'files',
  'rooms',
  'bookmarks',
  'dashboard',
  'video-call',
  'appointments',
  'shared-folders',
  'saved-products',
  'profile-settings',
  'saved-checklists',
  'followed-channels',
  'dashboard/profile'
];

class PlazaNext extends React.Component {
  componentDidCatch(error, errorInfo) {
    Sentry.withScope((scope) => {
      Object.keys(errorInfo).forEach((key) =>
        scope.setExtra(key, errorInfo[key])
      );

      Sentry.captureException(error);
    });
  }

  render() {
    const meta = contentMetadata[this.props.router.asPath];

    return (
      <ApolloProvider client={this.props.pageProps?.apolloClient}>
        <Head>
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0"
          />

          {meta && (
            <>
              {meta.title && <title>{meta.title}</title>}
              {meta.description && (
                <meta name="description" content={meta.description} />
              )}
              {meta.tags && <meta name="keywords" content={meta.tags} />}
            </>
          )}

          <link
            rel="canonical"
            href={`https://${config.HOST}${this.props.router.asPath}`}
          />
        </Head>

        <main>
          <MainSection {...this.props} />
        </main>
      </ApolloProvider>
    );
  }
}

export default withApollo(PlazaNext);

function MainSection(props) {
  const { Component, pageProps } = props;
  const { query, asPath } = props.router;

  const router = useRouter();

  const [path, queryString] = asPath.split('?');
  const isPreviewMode = query.mode === 'preview';

  const isHomePage = asPath === '/';
  const isDashboardPage = dashboardPages.some((page) =>
    asPath.startsWith(`/${page}`)
  );

  const isMainComponentOnly =
    [
      '/community/onboarding',
      '/zwangerschapscursus/info',
      '/betalen',
      '/betalen-ideal',
      '/betalen/gelukt',
      '/betalen/verzekering'
    ].find((uri) => path.endsWith(uri)) ||
    ['/verzekering/cursus-vergoeding'].find((uri) => path.startsWith(uri));

  const [
    getChannels,
    {
      data: channelsData = {},
      loading: channelsLoading,
      fetchMore: fetchMoreChannels
    }
  ] = useLazyQuery(popularChannels);

  const { data: userGroupsData } = useQuery(userGroups, {
    skip: !path.startsWith('/community/')
  });

  const { data: userSubscriptionsData } = useQuery(userSubscriptionsQuery, {
    skip: !path.startsWith('/zwangerschapscursus/')
  });

  const {
    data,
    error,
    refetch: refetchUserData,
    loading,
    startPolling,
    stopPolling
  } = useQuery(user);

  const { data: metadata = {}, refetch: metaRefetch } = useQuery(metaByPath, {
    variables: { path },
    skip: !path
  });

  const addUserEvent = useAddUserEventMutation();
  const addUserEventAndRedirect = useAddUserEventMutation(
    () => (location.href = 'dashboard')
  );
  const setUserPhoto = useSetUserPhotoMutation(() => router.reload());
  const markNotificationRead = useMarkNotificationReadMutation();
  const addWishlist = useAddWishlistMutationMutation(refetchUserData);
  const subscribeChannel = useSubscribeChannelMutation(refetchUserData);
  const [trackView, trackSearch, updateLastSeen] = useTrackingMutations();
  const unsubscribeChannel = useUnsubscribeChannelMutation(refetchUserData);
  const removeWishlist = useRemoveWishlistMutationMutation(refetchUserData);
  const [fvpDeals, setFvpDeals] = useState({});
  const [loginPopup, showLoginPopup] = useState(null);
  const [messagePopup, showMessagePopup] = useState({});
  const [discountProgramPopup, showDiscountProgramPopup] = useState(false);
  const [emailPreferenciesPopup, showEmailPreferenciesPopup] = useState(false);
  const userTasks = useUserTasks();

  useEffect(() => {
    if (query._p) setItem('_p', query._p);
    if (query._ph) setItem('_ph', query._ph);
  }, [query._p, query._ph]);

  useEffect(() => {
    if (error && error.message.includes('500')) {
      document.cookie = 'jwt=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
      setTimeout(() => (location.href = path), 500);
    }

    const { jwt } = query;

    if (jwt) {
      setCookie('jwt', jwt, 3);

      const url = getItem('originalUrl');

      if (url) location.href = url;
      else location.href = 'dashboard';
    } else if (forceRedirect(metadata.metaByPath, asPath)) {
      location.href = metadata.metaByPath.redirect;
    }
  });

  useEffect(() => {
    if (!data) return;

    const timerID = setInterval(() => {
      if (document.title) {
        clearInterval(timerID);

        loadMatomo(data.me || {}, query);
      }
    }, 100);

    setTimeout(() => clearInterval(timerID), 5000);
  }, [data]);

  useEffect(() => {
    if (asPath === '/') removeItem('originalUrl');
    else if (!query.jwt) setItem('originalUrl', asPath);

    if (me.username) updateLastSeen();

    trackView({ variables: { contentUri: asPath.split('/').pop() } });

    Router.events.on('routeChangeStart', (url) => {
      trackView({ variables: { contentUri: url.split('/').pop() } });

      if (me.username) updateLastSeen();

      if (window && window._paq) {
        window._paq.push(['setCustomUrl', url]);

        window._paq.push(['setDocumentTitle', document.title]);

        window._paq.push(['trackPageView']);
      }
    });
  }, []);

  useEffect(() => {
    if (!loading && query.scrollTo) {
      const isMobile = document.body.offsetWidth <= 850;

      const header = document.getElementById('header');
      const targetSection = document.getElementById(query.scrollTo);

      if (isMobile && targetSection)
        window.scrollTo(0, targetSection.offsetTop - header.offsetHeight);
    }
  }, [loading, query.scrollTo]);

  const me = (data && data.me) || {};

  const isLogged = !!me.username;

  const userSubscriptions = userSubscriptionsData?.me?.subscriptions || [];

  return (
    <>
      {!isPreviewMode && !isMainComponentOnly && (
        <Header
          user={me}
          path={path}
          query={query}
          jwt={props.jwt}
          NextLink={Link}
          {...header(me)}
          userTasks={userTasks}
          isHomePage={isHomePage}
          addUserEvent={addUserEvent}
          loginUrl={config.LOGIN_URL}
          signupUrl={config.SIGNUP_URL}
          isDashboardPage={isDashboardPage}
          refetchUserData={refetchUserData}
          userSubscriptions={userSubscriptions}
          markNotificationRead={markNotificationRead}
          defaultCommunityGroup={defaultCommunityGroup(
            me,
            userGroupsData?.userGroups
          )}
        />
      )}

      {privatePaths.includes(path) && !isLogged ? (
        <ClearToken url={config.LOGIN_URL} loading={loading} />
      ) : (
        <Component
          user={me}
          path={path}
          query={query}
          {...pageProps}
          {...header(me)}
          jwt={props.jwt}
          config={config}
          nextLink={Link}
          sentry={Sentry}
          asPath={asPath}
          NextLink={Link}
          fvpDeals={fvpDeals}
          isLogged={isLogged}
          userTasks={userTasks}
          isHomePage={isHomePage}
          stopPolling={stopPolling}
          trackSearch={trackSearch}
          setFvpDeals={setFvpDeals}
          queryString={queryString}
          addWishlist={addWishlist}
          getChannels={getChannels}
          setUserPhoto={setUserPhoto}
          startPolling={startPolling}
          loginUrl={config.LOGIN_URL}
          addUserEvent={addUserEvent}
          signupUrl={config.SIGNUP_URL}
          isPreviewMode={isPreviewMode}
          showLoginPopup={showLoginPopup}
          removeWishlist={removeWishlist}
          isDashboardPage={isDashboardPage}
          channelsLoading={channelsLoading}
          refetchUserData={refetchUserData}
          showMessagePopup={showMessagePopup}
          subscribeChannel={subscribeChannel}
          fetchMoreChannels={fetchMoreChannels}
          userSubscriptions={userSubscriptions}
          unsubscribeChannel={unsubscribeChannel}
          channelsData={channelsData.channels || []}
          markNotificationRead={markNotificationRead}
          showDiscountProgramPopup={showDiscountProgramPopup}
          showEmailPreferenciesPopup={showEmailPreferenciesPopup}
          userDataLoading={loading}
        />
      )}

      {!isMainComponentOnly && (
        <BottomMenu
          user={me}
          path={asPath}
          NextLink={Link}
          isHomePage={isHomePage}
          isDashboardPage={isDashboardPage}
        />
      )}

      {!props.isPreviewMode && !isMainComponentOnly && (
        <LazyLoadComponent>
          <Footer {...props} {...footer(me)} user={me} NextLink={Link} />
        </LazyLoadComponent>
      )}

      {query.popup === 'user-photo' && isLogged && (
        <UserPhotoPopup
          username={me.username}
          metaRefetch={metaRefetch}
          setUserPhoto={setUserPhoto}
          addUserEvent={addUserEvent}
          addUserEventAndRedirect={addUserEventAndRedirect}
        />
      )}

      {query.popup === 'channels' && isLogged && (
        <ChannelListPopup
          user={me}
          metaRefetch={metaRefetch}
          getChannels={getChannels}
          addUserEvent={addUserEvent}
          subscribeChannel={subscribeChannel}
          channels={channelsData.channels || []}
        />
      )}

      <Transition on={loginPopup}>
        <div className="popup-wrapper">
          <LoginPopup
            title={loginPopup}
            loginUrl={config.LOGIN_URL}
            signupUrl={config.SIGNUP_URL}
            showLoginPopup={showLoginPopup}
          />
        </div>
      </Transition>

      <Transition on={discountProgramPopup}>
        <div className="popup-wrapper">
          <DiscountProgramPopup
            showDiscountProgramPopup={showDiscountProgramPopup}
          />
        </div>
      </Transition>

      <Transition on={emailPreferenciesPopup}>
        <div className="popup-wrapper">
          <EmailPreferenciesPopup
            showEmailPreferenciesPopup={showEmailPreferenciesPopup}
          />
        </div>
      </Transition>

      <Transition on={messagePopup.message}>
        <div className="popup-wrapper">
          <MessagePopup
            messagePopup={messagePopup}
            showMessagePopup={showMessagePopup}
          />
        </div>
      </Transition>
    </>
  );
}

function ClearToken(props) {
  useEffect(() => {
    if (!props.loading) {
      document.cookie = 'jwt=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
      location.href = props.url;
    }
  });

  return <Preloader />;
}

function setCookie(name, value, exdays) {
  const date = new Date();

  date.setTime(date.getTime() + exdays * 24 * 60 * 60 * 1000);

  const expires = `expires=${date.toUTCString()}`;

  document.cookie = `${name}=${value};${expires};path=/`;
}

function loadMatomo(user) {
  if (document.getElementById('matomo')) return;

  if (user.username) {
    setItem('user', user.username);

    if (user.phase) setItem('phase', user.phase);

    if (user.provider && user.provider.accountName)
      setItem('provider', user.provider.accountName);
  }

  const script = document.createElement('script');

  script.id = 'matomo';
  script.src = '/tracking.js';

  document.body.appendChild(script);
}

function forceRedirect(meta, path) {
  return meta && meta.redirect && meta.redirect !== path;
}
